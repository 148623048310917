import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { graphql } from 'qonto/react/graphql';
import {
  type Pagination,
  type SearchTransactionsMeta,
  type SearchTransactionsQuery,
  type SearchTransactionsResult,
  type TransactionsSearch,
} from 'qonto/react/graphql';
import { useGraphQLRequest } from './use-graphql-request';

interface SearchTransactionsOptions {
  query?: TransactionsSearch;
  pagination: Pagination;
}

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;

export function useSearchTransactions(
  updateMeta: (meta: SearchTransactionsMeta) => void,
  options?: SearchTransactionsOptions
): UseQueryResult<SearchTransactionsResult> {
  const request = useGraphQLRequest<SearchTransactionsQuery>();

  return useQuery({
    queryKey: ['search-transactions-graphql', options] as const,
    queryFn: async ({ queryKey }) => {
      const [, searchOptions] = queryKey;
      const { pagination } = searchOptions ?? { pagination: {} };
      const payload: TransactionsSearch = {
        search: '',
        sort: {
          property: 'emitted_at',
          direction: 'desc',
        },
        ...(searchOptions?.query ?? {}),
        pagination: {
          page: pagination.page ?? DEFAULT_PAGE,
          perPage: pagination.perPage ?? DEFAULT_PER_PAGE,
        },
      };

      const { searchTransactions } = await request(
        graphql(/* GraphQL */ `
          query searchTransactions(
            $search: String
            $pagination: Pagination!
            $sort: Sort!
            $filterGroup: FilterGroupOne
            $bankAccountIds: [String!]
          ) {
            searchTransactions(
              input: {
                search: $search
                sort: $sort
                pagination: $pagination
                filterGroup: $filterGroup
                bankAccountIds: $bankAccountIds
              }
            ) {
              meta {
                totalCount
                totalPages
                currentPage
                perPage
                nextPage
                prevPage
              }
              transactions {
                amount {
                  value
                  currency
                }
                id
                initiator {
                  id
                  fullName
                  firstName
                  lastName
                  avatar {
                    url
                    thumbUrl
                  }
                  email
                  role
                  team {
                    id
                    name
                  }
                }
                activityTag
                counterpartyName
                emittedAt
                enrichmentData {
                  logo {
                    small
                    medium
                  }
                }
                localAmount {
                  value
                  currency
                }
                rawCounterpartyName
                operationMethod
                description
                note
                settledAt
                settledBalance {
                  value
                  currency
                }
                side
                slug
                status
                statusHistory {
                  status
                  createdAt
                }
                subjectType
                qualifiedForAccounting
                attachmentLost
                attachmentRequired
                attachmentIds
                attachmentRequested
                attachmentRequestedAt
                attachments {
                  thumbnail {
                    fileUrl
                  }
                  slug
                  id
                  downloadUrl
                  createdAt
                  file {
                    name
                    contentType
                    size
                    url
                  }
                  probativeAttachment {
                    status
                    fileContentType
                    fileName
                    fileSize
                    fileUrl
                    downloadUrl
                  }
                }
                bankAccount {
                  id
                  bic
                  iban
                  name
                }
                labels {
                  id
                  name
                  labelList {
                    id
                    name
                    color
                    rank
                  }
                  listId
                }
                thread {
                  id
                  attachedToId
                  attachedToType
                  createdAt
                  messages {
                    id
                    createdAt
                    content
                    author {
                      id
                      lastReadAt
                      membership {
                        fullName
                        email
                        role
                        avatar {
                          url
                          thumbUrl
                        }
                        team {
                          id
                          name
                        }
                      }
                    }
                  }
                  participants {
                    id
                    lastReadAt
                    membership {
                      fullName
                      email
                      role
                      avatar {
                        url
                        thumbUrl
                      }
                      team {
                        id
                        name
                      }
                    }
                  }
                }
                payableInvoices {
                  invoiceNumber
                  fileName
                  attachmentId
                }
                receivableInvoices {
                  invoiceNumber
                  attachmentId
                }
                transfer {
                  id
                  fx
                  slug
                  instant
                  internal
                  beneficiary {
                    bic
                    iban
                    name
                    id
                  }
                }
                income {
                  iban
                  name
                  bic
                  slug
                }
              }
            }
          }
        `),
        payload
      );
      return searchTransactions;
    },
  });
}
