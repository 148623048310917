import { useIntl, type IntlShape } from 'react-intl';
import type {
  CashflowCategory,
  CashflowCategories,
  CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';

export const useMapCategoriesWithData = (): {
  mapCategoriesWithData: (categories: CashflowCategories | undefined) => CashflowCategories;
} => {
  const intl = useIntl();
  return useCategoriesData(intl);
};

/**
 * Extracted for testing purposes, directly use useMapCategoriesWithData instead
 */
export const useCategoriesData = (
  intl: IntlShape
): {
  mapCategoriesWithData: (categories: CashflowCategories | undefined) => CashflowCategories;
} => {
  const defaultCategoriesCopyMap: Record<string, string> = {
    'inflows.sales': intl.formatMessage({ id: 'cash-flow-categories.default-inflows.sales.root' }),
    'inflows.subsidies': intl.formatMessage({
      id: 'cash-flow-categories.default-inflows.subsidies.root',
    }),
    'inflows.taxes.corporate-credit': intl.formatMessage({
      id: 'cash-flow-categories.default-inflows.taxes.corporate-credit',
    }),
    'inflows.taxes.vat-credit': intl.formatMessage({
      id: 'cash-flow-categories.default-inflows.taxes.vat-credit',
    }),
    'inflows.bank-loans': intl.formatMessage({
      id: 'cash-flow-categories.default-inflows.bank-loans.root',
    }),
    'inflows.internal-transfers': intl.formatMessage({
      id: 'cash-flow-categories.default-inflows.internal-transfers.root',
    }),
    'inflows.other': intl.formatMessage({
      id: 'cash-flow-categories.default-inflows.other.root',
    }),
    'outflows.operational': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.root',
    }),
    'outflows.travel': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.travel',
    }),
    'outflows.employees': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.root',
    }),
    'outflows.marketing': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.marketing.root',
    }),
    'outflows.technology': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.technology.root',
    }),
    'outflows.admin': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.root',
    }),
    'outflows.restaurants': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.restaurants.root',
    }),
    'outflows.internal-transfers': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.internal-transfers.root',
    }),
    'outflows.banks': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.banks.root',
    }),
    'outflows.taxes': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.taxes.root',
    }),
    'outflows.operational.production': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.production',
    }),
    'outflows.operational.rent': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.rent',
    }),
    'outflows.operational.utilities': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.utilities',
    }),
    'outflows.operational.office': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.office',
    }),
    'outflows.operational.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.operational.other',
    }),
    'outflows.travel.accommodation': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.travel.accommodation',
    }),
    'outflows.travel.transport': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.travel.transport',
    }),
    'outflows.travel.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.travel.other',
    }),
    'outflows.employees.salaries': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.salaries',
    }),
    'outflows.employees.perks': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.perks',
    }),
    'outflows.employees.training': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.training',
    }),
    'outflows.employees.social': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.social',
    }),
    'outflows.employees.subcontractors': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.subcontractors',
    }),
    'outflows.employees.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.employees.other',
    }),
    'outflows.marketing.digital': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.marketing.digital',
    }),
    'outflows.marketing.offline': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.marketing.offline',
    }),
    'outflows.marketing.hospitality': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.marketing.hospitality',
    }),
    'outflows.marketing.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.marketing.other',
    }),
    'outflows.technology.software': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.technology.software',
    }),
    'outflows.technology.hosting': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.technology.hosting',
    }),
    'outflows.technology.hardware': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.technology.hardware',
    }),
    'outflows.technology.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.technology.other',
    }),
    'outflows.admin.legal': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.legal',
    }),
    'outflows.admin.accounting': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.accounting',
    }),
    'outflows.admin.services': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.services',
    }),
    'outflows.admin.insurance': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.insurance',
    }),
    'outflows.admin.hr': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.hr',
    }),
    'outflows.admin.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.admin.other',
    }),
    'outflows.banks.loans': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.banks.loans',
    }),
    'outflows.banks.credit-cards': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.banks.credit-cards',
    }),
    'outflows.banks.subscriptions': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.banks.subscriptions',
    }),
    'outflows.banks.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.banks.other',
    }),
    'outflows.taxes.income': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.taxes.income',
    }),
    'outflows.taxes.vat': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.taxes.vat',
    }),
    'outflows.taxes.other': intl.formatMessage({
      id: 'cash-flow-categories.default-outflows.taxes.other',
    }),
  };

  const getCategoryData = <T extends CashflowCategory>(category: T): T => {
    const { colorKey, name, nameKey } = category;

    const categoryData = {
      name: nameKey ? defaultCategoriesCopyMap[nameKey] ?? name : name,
      colorKey: colorsMap[colorKey] ?? colorsMap.default,
    };

    return { ...category, ...categoryData } as T;
  };

  const applyCategoriesData = (
    flowCategories: CashflowParentCategory[]
  ): CashflowParentCategory[] => {
    return [...flowCategories].map((category: CashflowParentCategory) => {
      const enhancedCategory = getCategoryData(category);

      if (enhancedCategory.subcategories.length) {
        enhancedCategory.subcategories = enhancedCategory.subcategories.map(subcategory => ({
          ...getCategoryData(subcategory),
          iconKey: enhancedCategory.iconKey,
        }));
      }

      return enhancedCategory;
    });
  };

  const mapCategoriesWithData = (
    cashflowCategories: CashflowCategories | undefined
  ): CashflowCategories => {
    if (!cashflowCategories) {
      return { inflow: [], outflow: [] };
    }

    const categoriesWithData = {
      inflow: applyCategoriesData(cashflowCategories.inflow),
      outflow: applyCategoriesData(cashflowCategories.outflow),
    };

    return categoriesWithData;
  };

  return { mapCategoriesWithData };
};

export const colorsMap: Record<string, string> = {
  category_mint: '--category-mint',
  category_mustard: '--category-mustard',
  category_peach: '--category-peach',
  category_blue: '--category-blue',
  category_green: '--category-green',
  category_pink: '--category-pink',
  category_gray: '--category-gray',
  default: '--category-gray',
};
