import type { PropsWithChildren, ReactNode } from 'react';
import {
  type LDFlagSet,
  LDProvider,
  PolymorphicRouterContextProvider,
} from '@qonto/react-migration-toolkit/react/providers';
import { useEmberIntl, useEmberRouter } from '@qonto/react-migration-toolkit/react/hooks/providers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RawIntlProvider } from 'react-intl';
import { PolymorphicSegmentProvider } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';

const queryClient = new QueryClient();
interface CustomProvidersProps extends PropsWithChildren {
  ldFlags: LDFlagSet;
}

export function CustomProviders({ children, ldFlags }: CustomProvidersProps): ReactNode {
  const intl = useEmberIntl();
  const router = useEmberRouter();
  const segment = useEmberService('segment');

  return (
    <PolymorphicSegmentProvider segment={segment}>
      <LDProvider ldFlags={ldFlags}>
        <PolymorphicRouterContextProvider router={router}>
          <RawIntlProvider value={intl}>
            <QueryClientProvider client={queryClient}>
              {children}
              <ReactQueryDevtools buttonPosition="bottom-left" />
            </QueryClientProvider>
          </RawIntlProvider>
        </PolymorphicRouterContextProvider>
      </LDProvider>
    </PolymorphicSegmentProvider>
  );
}
