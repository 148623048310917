import { useQuery } from '@tanstack/react-query';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import { type CashflowCategories } from '../models/cash-flow-category';
import type { CashflowCategories as CashflowCategoriesApiResponse } from '../api/models/cash-flow-category';
import { camelizeKeys } from '../api/utils/camelize-keys';
import type { QueryResult } from '../api/utils/query-result';
import { useMapCategoriesWithData } from '../utils/cashflow-categories/categories-data-map';
import { useFetchApi } from './use-fetch-api';

export async function fetchCashflowCategories(
  organizationId: string,
  fetchApi: ReturnType<typeof useFetchApi>
): Promise<CashflowCategories | undefined> {
  const endpointUrl = `${cashFlowCategoriesNamespace}/cash-flow/categories?organization_id=${organizationId}`;
  const response = await fetchApi(endpointUrl);

  if (!response.ok) {
    throw new Error(`Error fetching categories for organization: ${organizationId}`);
  }

  const data = (await response.json()) as CashflowCategoriesApiResponse;
  const categories = camelizeKeys(data.categories) as CashflowCategories;

  return categories;
}

export function useFetchCashflowCategories(
  organizationId: string
): QueryResult<CashflowCategories> {
  const fetchApi = useFetchApi();
  const { mapCategoriesWithData } = useMapCategoriesWithData();

  const {
    data,
    isLoading: isPending,
    isError,
  } = useQuery({
    queryKey: ['categories', organizationId],
    queryFn: () => fetchCashflowCategories(organizationId, fetchApi),
    staleTime: 1000 * 60 * 30, // 30 min,
    refetchOnWindowFocus: false,
  });

  return {
    data: mapCategoriesWithData(data),
    isPending,
    isError,
  };
}
